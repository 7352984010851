import { EnvironmentOutlined, ExpandOutlined, HomeOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Form, Row, notification } from 'antd';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment-jalaali';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAntdValidation, useHttpCall } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { toQueryString } from '../../../../utils/helpers';
import { FormItemActions, ImprovedAutoComplete, PDatePicker2 } from '../../../shared';
import { DomesticHotelLocationResponseItem, GetDomesticHotelLocationResponse } from '../../apiTypes';
import { getHotelLocationHttp } from '../../http/domesticHotel-http';
import classes from './DomesticHotelSearchForm.module.scss';

type DomesticHotelFormValues = Readonly<{
  checkInCheckOut: [Moment, Moment];
  [key: string]: any;
}>;
type Props = {
  inline?: boolean;
  forceDestination?: number;
  hide: boolean;
  defaultValue?: {
    checkinCheckout: string[];
    location: GetDomesticHotelLocationResponse;
  };
};
const DomesticHotelSearchForm: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [hotelLocations, setHotelLocations] = useState<any>([]);

  const getLocationApi = useHttpCall(getHotelLocationHttp);

  const [form] = Form.useForm<DomesticHotelFormValues>();
  const { labelWithRules } = useAntdValidation(form);

  // useEffect(() => {
  //   if (props.hide) {
  //     setDropDownAdditionVisibleCondition(false);
  //   }
  // }, [props.hide]);

  useEffect(() => {
    if (props.defaultValue && props.defaultValue.checkinCheckout) {
      form.setFieldsValue({
        checkinCheckout: props.defaultValue.checkinCheckout,
      });
    }
  }, [form, props.defaultValue]);

  const debouncedGetLocationApi = debounce(async (e) => {
    const response = await getLocationApi.call<GetDomesticHotelLocationResponse>(e);
    if (response) {
      setHotelLocations(response.result);
      // setDropDownAdditionVisibleCondition(true);
    }
  }, 300);

  const handleDestinationSearched = async (value: string) => {
    if (value.length > 2) {
      await debouncedGetLocationApi(value);
    } else {
      // setDropDownAdditionVisibleCondition(false);
    }
  };

  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleSearch = async (values: any) => {
    const hotelsQueryString = {
      destination: values.destination.id,
      checkIn: moment(values.checkinCheckout[0]).format('YYYY-MM-DD'),
      checkOut: moment(values.checkinCheckout[1]).format('YYYY-MM-DD'),
    };
    if (moment(hotelsQueryString.checkIn).isAfter(hotelsQueryString.checkOut)) {
      notification.error({
        message: 'Check-out date should be after check-in date!',
      });
      return;
    }
    const hotelDetailsQueryString = {
      hotelId: values.destination.id,
      checkIn: moment(values.checkinCheckout[0]).format('YYYY-MM-DD'),
      checkOut: moment(values.checkinCheckout[1]).format('YYYY-MM-DD'),
    };
    if (values.destination.type === 'Hotel') {
      const query = toQueryString(hotelDetailsQueryString);
      history.push(`/domestic/hotel?${query}`);
    } else {
      const query = toQueryString(hotelsQueryString);
      history.push(`/domestic/hotels?${query}`);
    }
  };

  return (
    <>
      <Form<DomesticHotelFormValues>
        form={form}
        onFinish={handleSearch}
        labelCol={{ span: 24 }}
        className={`hotel-search-form ${props.inline ? 'inline-search-form' : ''}`}>
        <Row gutter={[10, 0]} align={'top'}>
          {/*{props.defaultValue.location.SearchValue}*/}
          <Col xs={24} sm={props.inline ? 8 : 24} xl={props.inline ? 8 : 24}>
            {
              //props.defaultValue?.location &&
              <Form.Item
                name="destination"
                initialValue={props.defaultValue?.location}
                className="mb-4"
                {...labelWithRules({ label: t(Translations.Common.Destination), rules: [{ type: 'Required' }] })}>
                <ImprovedAutoComplete
                  overlayClassName={'font-fa'}
                  defaultItem={props.defaultValue?.location}
                  //additionVisibleCondition={dropdownAdditionVisibleCondition}
                  textPropertyName="name"
                  onInputChanged={handleDestinationSearched}
                  options={hotelLocations}
                  asyncPending={getLocationApi.pending}
                  noPaddingRenderItem
                  renderOption={(option: DomesticHotelLocationResponseItem) => (
                    <div className={`py-1 px-2 ${option.type === 'Hotel' ? classes.blue : ''}`}>
                      <Row gutter={[10, 10]} align={'top'} className="nowrap-flex font-fa">
                        <Col>{option.type === 'Hotel' ? <HomeOutlined /> : option.type === 'Province' ? <ExpandOutlined /> : <EnvironmentOutlined />}</Col>
                        <Col>
                          <div className="py-1">
                            <b>{option.name}</b>
                          </div>
                          <div> {option.displayName}</div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  renderTooltip={(option) => (
                    <>
                      {option.name}
                      <br />
                      {option.displayName}
                    </>
                  )}
                />
              </Form.Item>
            }
          </Col>
          <Col xs={24} sm={props.inline ? 10 : 24} md={props.inline ? 8 : 24} xl={props.inline ? 8 : 24}>
            <Form.Item
              className="mb-4"
              name="checkinCheckout"
              initialValue={props?.defaultValue?.checkinCheckout}
              {...labelWithRules({ label: t(Translations.Hotel.CheckInAndCheckOut), rules: [{ type: 'Required' }] })}>
              <PDatePicker2
                mode="range"
                minDate={moment().startOf('day').format('jYYYY-jMM-jDD')}
                //onChange={handleCheckout}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={props.inline ? 6 : 24}
            md={props.inline ? 8 : 24}
            xl={props.inline ? 8 : 24}
            className={`min-width-100  ${props.inline ? 'text-right' : ''}`}>
            <div className={props.inline ? 'align-with-formItems-sm text-right' : 'mt-2'}>
              <FormItemActions
                formInstance={form}
                onReset={handleReset}
                submitText={props.forceDestination ? t(Translations.Common.ModifySearch) : t(Translations.Common.Search)}
                submitIcon={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default DomesticHotelSearchForm;
