import Axios from 'axios';

import { AxiosInstanceData, GetAllRequestBaseOptional } from '../../../types';
import { isDevelopment, urlWithQuery } from '../../../utils/helpers';
import { CurrencyName } from '../../shared';
import {
  CreateManualReceiptClientRequest,
  DepositGatewayMakeTokenRequest,
  GatewayMakeTokenRequest,
  GetDepositTransactionsRequest,
  GetDepositTransactionsToExcelRequest,
  ManualReceiptClientPaymentStatus,
  ManualReceiptClientPaymentType,
} from '../apiTypes';
import { RefundRequest } from '../apiTypes';
import { PaymentApiDataMap, PaymentApiUrls } from './payment-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_PaymentApiBaseUrl;

if (isDevelopment()) {
  // const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 0 });
  // paymentMock(mock);
}

export const getAllAccountNumbersHttp = (data: { CurrencyType: string } & GetAllRequestBaseOptional) => {
  const url = urlWithQuery(PaymentApiUrls.GetAllAccountNumbers, data);
  return axiosInstance.get(url);
};

export const getDepositTransactionsHttp = (data: GetDepositTransactionsRequest) => {
  const url = urlWithQuery(PaymentApiUrls.DepositTransactions, data);
  return axiosInstance.get(url);
};

export const getDepositBalancesHttp = () => {
  return axiosInstance.get(PaymentApiUrls.DepositBalances);
};

export const createManualReceiptClientHttp = (data: CreateManualReceiptClientRequest) => {
  return axiosInstance.post(PaymentApiUrls.CreateManualReceiptClient, data);
};

export const getAllManualReceiptClient = (
  data: Partial<{
    CreationTimeFrom: Date;
    CreationTimeTo: Date;
    TransferTimeFrom: Date;
    TransferTimeTo: Date;
    BankBrand: string;
    HolderName: string;
    Type: ManualReceiptClientPaymentType;
    AmountFrom: number;
    AmountTo: number;
    TransactionNumber: string;
    AccountNumber: string;
    BankAccountId: number;
    TenantId: number;
    CurrencyTypes: keyof typeof CurrencyName[];
    Statuses: ManualReceiptClientPaymentStatus[];
  }> &
    GetAllRequestBaseOptional
) => {
  const url = urlWithQuery(PaymentApiUrls.GetAllManualReceiptClients, data);
  return axiosInstance.get(url);
};

export const paymentGetDepositGatewaysHttp = (data: { currencyType: string }) => {
  const url = urlWithQuery(PaymentApiUrls.GetDepositGateways, data);
  return axiosInstance.get(url);
};

export const paymentDepositGatewayMakeTokenHttp = (data: DepositGatewayMakeTokenRequest) => {
  return axiosInstance.post(PaymentApiUrls.DepositGatewayMakeToken, data);
};

export const paymentRefundHttp = (data: RefundRequest) => {
  data = { ...data, type: 'Affiliate' };
  return axiosInstance.post(PaymentApiUrls.Refund, data);
};

export const paymentGetReserveDepositBalanceHttp = (data: { ReserveId: string; Username: string }) => {
  const url = urlWithQuery(PaymentApiUrls.ReserveDepositBalance, data);
  return axiosInstance.get(url);
};

export const paymentConfirmByDepositHttp = (data: { reserveId: number; username: string }) => {
  return axiosInstance.post(PaymentApiUrls.ConfirmByDeposit, data);
};

export const paymentRefundDepositHttp = (data: { reserveId: number; username: string }) => {
  return axiosInstance.post(PaymentApiUrls.DepositRefund, data);
};

export const paymentGetReserveGatewaysHttp = (data: { reserveId: string }) => {
  const url = urlWithQuery(PaymentApiUrls.GetReserveGateways, data);
  return axiosInstance.get(url);
};

export const paymentReserveGatewayMakeTokenHttp = (data: GatewayMakeTokenRequest) => {
  return axiosInstance.post(PaymentApiUrls.ReserveGatewayMakeToken, data);
};

export const paymentGetTransactionsToExcelHttp = (data: GetDepositTransactionsToExcelRequest) => {
  const url = urlWithQuery(PaymentApiUrls.GetTransactionsToExcel, data);
  return axiosInstance.get(url);
};

export const PaymentAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: PaymentApiDataMap,
    Urls: PaymentApiUrls,
  },
};
