import { ApiDataItem } from '../../../types';

export enum HotelApiUrls {
  //BookingHotel
  GetLocation = '/api/services/app/BookingHotel/GetLocation',
  GetSearch = '/api/services/app/BookingHotel/GetSearch',
  GetHotelById = '/api/services/app/BookingHotel/GetHotelById',
  GetCancellationPolicy = 'api/services/app/BookingHotel/GetCancellationPolicy',
  PreReserve = '/api/services/app/BookingHotel/PreReserve',
  GetPreReserveByKey = '/api/services/app/BookingHotel/GetPreReserveByKey',
  Reserve = '/api/services/app/BookingHotel/Reserve',
  GetReserveById = '/api/services/app/BookingHotel/GetReserveById',
  Book = '/api/services/app/BookingHotel/Book',
  GetPreCancel = '/api/services/app/BookingHotel/GetPreCancel',
  Cancel = '/api/services/app/BookingHotel/Cancel',

  //TenantReserve
  GetAllTenantReserves = '/api/services/app/TenantReserve/GetAll',
  GetTenantReserve = '/api/services/app/TenantReserve/Get',
}

export const HotelApiDataMap: Map<string, ApiDataItem> = new Map();
