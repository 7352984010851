import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetAllRequestBase, SortOrder } from '../../../types';
import {
  DepartureAirport,
  FlightCabinClass,
  FlightGetAllTenantReservesRequest,
  FlightGetAllTenantReservesResponse,
  FlightPricedItinerary,
  FlightReserveItem,
  FlightSupplier,
  GetPNRDetailsResponse,
  LowFareSearchRequest,
  OperatingAirline,
  PNRStatus,
} from '../apiTypes';
import {
  buildAirportsFilterArray,
  buildCabinClassesFilterArray,
  buildFlightsBaggageNumbersFilterArray,
  buildFlightsDurationFilterArray,
  buildFlightsStopTimeFilterArray,
  buildFromToHourRangeFilterArray,
} from '../helpers/FlightHelpers';
import { FlightFilterItem, FlightFilterValueTypes, FlightItinerarySummary, FlightTripType, FlightsSortProperty } from '../types';
import { buildMarketingAirlinesFilterArray, convertApiItineraryToItinerarySummary } from '..';

type FlightState = {
  suppliers: FlightSupplier[];

  //Note : Apparently it is different than filtering in the client. That's why its name (suppliersApiHasTicketingTrue) has 'Api' in it.
  suppliersApiHasTicketingTrue?: FlightSupplier[];

  temp: {
    flightSearch: FlightSearchTempState;
    flights: {
      initialized: boolean;
      constants: {
        searchData?: LowFareSearchRequest;
        itinerariesSummary: FlightItinerarySummary[];
        fromToHourRange?: {
          departure: [number, number];
          arrival: [number, number];
        }[];
        cabinClasses: FlightCabinClass[];
        durationMinMax: [number, number];
        stopTimeMinMax: [number, number];
        marketingAirlines: {
          airline: OperatingAirline;
          minPrice: number;
        }[];
        airports: DepartureAirport[];
        baggageNumbers: string[];
      };
      page: number;
      pageSize: number;
      filters: {
        baggage?: string[];
        baggageNumber?: string[];
        cabinClasses?: string[];
        duration?: [number, number];
        stopTime?: [number, number];
        airlines?: string[];
        airports?: string[];
        stop?: number;
        fromToHourRanges: {
          optionIndex: number;
          departureTimes?: [number, number];
          arrivalTimes?: [number, number];
        }[];
        airlineWithStop?: {
          airline: string;
          direct: boolean;
        }[];
      };
      sort: {
        property: FlightsSortProperty;
        order: SortOrder;
      };
      responseDateNumber: number;
      apiPending: boolean;
      airportCodes?: string[];
      expirationTime?: string;
    };
    ticketing: {
      pnrDetails?: GetPNRDetailsResponse;
      pending: boolean;
      pendingAction?: 'GettingInformation' | 'Canceling' | 'Pricing' | 'Issuing';
    };
    reserves: {
      request: {
        filters?: Omit<FlightGetAllTenantReservesRequest, keyof GetAllRequestBase>;
        pagination: { page: number; pageSize: number };
        type: 'Initialization' | 'Pagination' | 'Filter';
      };
      response?: { totalCount: number; items: FlightReserveItem[] };
    };
  };
};

type FlightSearchTempState = {
  tripType: FlightTripType;
  qty: number;
};

const tempFlightSearchInitialState: FlightState['temp']['flightSearch'] = {
  tripType: 'RoundTrip',
  qty: 1,
};
const tempFlightsInitialState: FlightState['temp']['flights'] = {
  initialized: false,
  constants: {
    itinerariesSummary: [],
    marketingAirlines: [],
    airports: [],
    cabinClasses: [],
    durationMinMax: [0, 0],
    stopTimeMinMax: [0, 0],
    baggageNumbers: [],
  },
  page: 1,
  pageSize: 20,
  filters: {
    fromToHourRanges: [],
  },
  sort: {
    property: 'Price',
    order: 'Ascending',
  },
  responseDateNumber: Date.now(),
  apiPending: false,
};
const tempTicketingInitialState: FlightState['temp']['ticketing'] = {
  pending: false,
};
const tempReservesInitialState: FlightState['temp']['reserves'] = {
  request: {
    pagination: { pageSize: 20, page: 1 },
    type: 'Initialization',
  },
};

const initialState: FlightState = {
  suppliers: [
    { supplierName: 'Sabre', supplierId: 2, hasTicketing: true },
    { supplierName: 'Pegasus', supplierId: 5, hasTicketing: false },
    { supplierName: 'Amadeus', supplierId: 8, hasTicketing: true },
    { supplierName: 'TravelFusion', supplierId: 9, hasTicketing: false },
    { supplierName: 'AmadeusThr', supplierId: 10, hasTicketing: true },
    { supplierName: 'Mahan', supplierId: 11, hasTicketing: false },
    { supplierName: 'Farelogix', supplierId: 12, hasTicketing: true },
    { supplierName: 'IRTech', supplierId: 15, hasTicketing: false },
    { supplierName: 'Ethnic', supplierId: 17, hasTicketing: true },
    { supplierName: 'Chartex', supplierId: 19, hasTicketing: false },
    { supplierName: 'SabreSWE', supplierId: 21, hasTicketing: false },
    { supplierName: 'HobsFare', supplierId: 21, hasTicketing: false },
  ],
  temp: {
    flightSearch: tempFlightSearchInitialState,
    flights: tempFlightsInitialState,
    ticketing: tempTicketingInitialState,
    reserves: tempReservesInitialState,
  },
};

const flightSlice = createSlice({
  name: 'flight',
  initialState: initialState,
  reducers: {
    setSuppliers: (state, action: PayloadAction<FlightSupplier[]>) => {
      state.suppliers = action.payload;
    },
    setApiHasTicketingTrueSuppliers: (state, action: PayloadAction<FlightSupplier[]>) => {
      state.suppliersApiHasTicketingTrue = action.payload;
    },
    tempSetFlightSearch: (state, action: PayloadAction<Partial<FlightSearchTempState>>) => {
      state.temp.flightSearch = { ...state.temp.flightSearch, ...action.payload };
    },
    tempSetFlightsApiPending: (state, action: PayloadAction<boolean>) => {
      state.temp.flights.apiPending = action.payload;
    },
    tempSetFlightsAirports: (state, action: PayloadAction<string[]>) => {
      state.temp.flights.airportCodes = action.payload;
    },
    tempSetNewFlightItineraries: (
      state,
      action: PayloadAction<{ itineraries: FlightPricedItinerary[]; searchData: LowFareSearchRequest; dateNumber: number; expirationDate: string }>
    ) => {
      state.temp.flights.page = 1;
      state.temp.flights.responseDateNumber = action.payload.dateNumber;
      state.temp.flights.expirationTime = action.payload.expirationDate;

      state.temp.flights.filters = {
        fromToHourRanges: [],
      };
      state.temp.flights.sort = {
        property: 'Price',
        order: 'Ascending',
      };

      const itinerariesSummary = action.payload.itineraries.map<FlightItinerarySummary>((item) => convertApiItineraryToItinerarySummary(item, state.suppliers));

      state.temp.flights.constants.itinerariesSummary = itinerariesSummary;
      state.temp.flights.constants.searchData = action.payload.searchData;

      if (itinerariesSummary.length === 0) {
        state.temp.flights.constants = {
          itinerariesSummary: [],
          marketingAirlines: [],
          airports: [],
          cabinClasses: [],
          durationMinMax: [0, 0],
          stopTimeMinMax: [0, 0],
          baggageNumbers: [],
        };
        state.temp.flights.apiPending = false;
        state.temp.flights.initialized = true;
        return;
      }

      state.temp.flights.constants.fromToHourRange = buildFromToHourRangeFilterArray(itinerariesSummary);
      state.temp.flights.constants.marketingAirlines = buildMarketingAirlinesFilterArray(itinerariesSummary);
      state.temp.flights.constants.airports = buildAirportsFilterArray(itinerariesSummary);
      state.temp.flights.constants.cabinClasses = buildCabinClassesFilterArray(itinerariesSummary);
      state.temp.flights.constants.durationMinMax = buildFlightsDurationFilterArray(itinerariesSummary);
      state.temp.flights.constants.stopTimeMinMax = buildFlightsStopTimeFilterArray(itinerariesSummary);
      state.temp.flights.constants.baggageNumbers = buildFlightsBaggageNumbersFilterArray(itinerariesSummary);

      state.temp.flights.apiPending = false;
      state.temp.flights.initialized = true;
    },
    tempSetPage: (state, action: PayloadAction<{ page: number; pageSize?: number }>) => {
      state.temp.flights.page = action.payload.page;
      state.temp.flights.pageSize = action.payload.pageSize ?? 20;
    },
    tempSortList: (state, action: PayloadAction<{ property: FlightsSortProperty; order: SortOrder }>) => {
      if (action.payload.order === undefined) {
        state.temp.flights.sort = { property: 'Price', order: 'Ascending' };
      } else {
        state.temp.flights.sort = action.payload;
      }
    },
    tempFilter: (
      state,
      action: PayloadAction<{
        type: FlightFilterItem;
        value: FlightFilterValueTypes;
      }>
    ) => {
      switch (action.payload.type) {
        case 'Stop':
          state.temp.flights.filters.stop = action.payload.value as number;
          break;
        case 'CabinClass':
          state.temp.flights.filters.cabinClasses = action.payload.value as string[];
          break;
        case 'Baggage':
          state.temp.flights.filters.baggage = action.payload.value as string[];
          break;
        case 'BaggageNumber':
          state.temp.flights.filters.baggageNumber = action.payload.value as string[];
          break;
        case 'Duration':
          state.temp.flights.filters.duration = action.payload.value as [number, number];
          break;
        case 'StopTime':
          state.temp.flights.filters.stopTime = action.payload.value as [number, number];
          break;
        case 'Airline':
          const airlines = action.payload.value as string[];
          state.temp.flights.filters.airlines = airlines;
          break;
        case 'DepartureTime':
          const departureTimeFilterValue = action.payload.value as { optionIndex: number; times?: [number, number] };
          const obj1 = state.temp.flights.filters.fromToHourRanges.find((f) => f.optionIndex === departureTimeFilterValue.optionIndex);
          if (obj1) {
            obj1.departureTimes = departureTimeFilterValue.times;
          } else {
            state.temp.flights.filters.fromToHourRanges.push({
              optionIndex: departureTimeFilterValue.optionIndex,
              departureTimes: departureTimeFilterValue.times,
            });
          }
          break;
        case 'ArrivalTime':
          const arrivalTimeFilterValue = action.payload.value as { optionIndex: number; times?: [number, number] };
          const obj2 = state.temp.flights.filters.fromToHourRanges.find((f) => f.optionIndex === arrivalTimeFilterValue.optionIndex);
          if (obj2) {
            obj2.arrivalTimes = arrivalTimeFilterValue.times;
          } else {
            state.temp.flights.filters.fromToHourRanges.push({
              optionIndex: arrivalTimeFilterValue.optionIndex,
              arrivalTimes: arrivalTimeFilterValue.times,
            });
          }
          break;
        case 'Airport':
          state.temp.flights.filters.airports = action.payload.value as string[];
          break;
        case 'AirlineWithStop':
          state.temp.flights.filters.airlineWithStop = action.payload.value as { airline: string; direct: boolean }[];
          break;
      }
      state.temp.flights.page = 1;
    },
    tempClean: (state) => {
      state.temp.flights = tempFlightsInitialState;
      state.temp.flightSearch = tempFlightSearchInitialState;
      state.temp.ticketing = tempTicketingInitialState;
      state.temp.reserves = tempReservesInitialState;
    },
    tempTicketingSetPending: (state, action: PayloadAction<'GettingInformation' | 'Canceling' | 'Pricing' | 'Issuing' | undefined>) => {
      state.temp.ticketing.pending = action.payload !== undefined;
      state.temp.ticketing.pendingAction = action.payload;
    },
    tempTicketingSetPNRDetails: (state, action: PayloadAction<GetPNRDetailsResponse>) => {
      state.temp.ticketing.pnrDetails = action.payload;
      state.temp.ticketing.pending = false;
      state.temp.ticketing.pendingAction = undefined;
    },
    tempTestTicketingSetPNRStatus: (state, action: PayloadAction<PNRStatus>) => {
      state.temp.ticketing.pending = false;
      state.temp.ticketing.pnrDetails!.pnrStatus = action.payload;
      state.temp.ticketing.pendingAction = undefined;
    },
    tempReservesFilter: (state, action: PayloadAction<Omit<FlightGetAllTenantReservesRequest, keyof GetAllRequestBase>>) => {
      state.temp.reserves.request.filters = action.payload;
      state.temp.reserves.request.type = 'Filter';
    },
    tempReservesPagination: (state, action: PayloadAction<{ page: number; pageSize: number }>) => {
      state.temp.reserves.request.pagination = action.payload;
      state.temp.reserves.request.type = 'Pagination';
    },
    tempReservesSetResponse: (state, action: PayloadAction<FlightGetAllTenantReservesResponse>) => {
      state.temp.reserves.response = action.payload;
    },
  },
});

export default flightSlice;
