import { Form, Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Translations } from '../../../../../features/localization';
import { flightSlice } from '../../../index';
import { FlightTripType } from '../../../types';

const FlightTripTypeFormItem: React.VFC = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleTripTypeChanged = (value: FlightTripType) => {
    let qty = 1;
    if (value === 'OpenJaw') {
      qty = 2;
    }

    dispatch(flightSlice.actions.tempSetFlightSearch({ tripType: value, qty: qty }));
  };

  return (
    <Form.Item className="mb-3" name="tripType" initialValue="RoundTrip">
      <Radio.Group onChange={(event) => handleTripTypeChanged(event.target.value)} className="vertical-in-mobile">
        <Radio value="OneWay">{t(Translations.Flight.OneWay)}</Radio>
        <Radio value="RoundTrip">{t(Translations.Flight.RoundTrip)}</Radio>
        <Radio value="OpenJaw">{t(Translations.Flight.MultipleDestination)}</Radio>
      </Radio.Group>
    </Form.Item>
  );
});

export default FlightTripTypeFormItem;
