import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Modal, Row } from 'antd';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useGlobalization, useMount } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { CultureName } from '../../../../../features/localization/cultures';
import { fromJson, fromQueryString, momentToISOUtcDate, toBoolean, toJson, toQueryString } from '../../../../../utils/helpers';
import { addToRecentSearches } from '../../../../booking';
import { FormItemActions } from '../../../../shared';
import { GetAirlineResponse } from '../../../apiTypes';
import { convertFlightsQueryStringObjectToSearchData } from '../../../helpers/FlightHelpers';
import { flightSlice } from '../../../index';
import { FlightFormValues, FlightsQueryString } from '../../../types';
import FlightSearchFlights from '../FlightSearchFlights/FlightSearchFlights';
import FlightSearchOptions from '../FlightSearchOptions/FlightSearchOptions';
import FlightSearchPassengers from '../FlightSearchPassengers/FlightSearchPassengers';
import FlightTripTypeFormItem from '../FlightTripTypeFormItem/FlightTripTypeFormItem';

type Props = {
  isEthnic: boolean;
  inline?: boolean;
  fillFromQueryString?: boolean;
  submitPending?: boolean;
};
const FlightSearchForm: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryStringObj = fromQueryString<FlightsQueryString>(location.search, 'bracket');

  const { culture } = useGlobalization();

  const history = useHistory();

  const [repeatSearchModalVisibility, setRepeatSearchModalVisibility] = useState(false);

  const [form] = Form.useForm<FlightFormValues>();

  const repeatSearchRef = useRef(false);

  useMount(() => {
    return () => {
      dispatch(flightSlice.actions.tempClean());
    };
  });

  useEffect(() => {
    if (props.fillFromQueryString) {
      let roundTripDateTimes = undefined;
      if (queryStringObj.airTripType === 'RoundTrip') {
        const departure = moment(queryStringObj.departureDateTimes[0]).utc(true);
        const arrive = moment(queryStringObj.departureDateTimes[1]).utc(true);
        if (culture.Name === CultureName.FaIr) {
          roundTripDateTimes = [departure.toISOString(), arrive.toISOString()];
        } else {
          roundTripDateTimes = [departure, arrive];
        }
      }

      let departuresDates;
      if (culture.Name === CultureName.FaIr) {
        departuresDates = queryStringObj.departureDateTimes.map((item) => moment(item).utc(true).toISOString());
      } else {
        departuresDates = queryStringObj.departureDateTimes.map((item) => moment(item).utc(true));
      }

      form.setFieldsValue({
        tripType: queryStringObj.airTripType,

        origins: queryStringObj.originCodes.map((item, index) => ({ Code: item, Name: queryStringObj.originsText[index] })),
        destinations: queryStringObj.destinationCodes.map((item, index) => ({ Code: item, Name: queryStringObj.destinationsText[index] })),
        departures: departuresDates,
        roundTripDates: roundTripDateTimes,

        adults: +queryStringObj.adult,
        children: +queryStringObj.child,
        infants: +queryStringObj.infant,

        cabinClass: queryStringObj.cabinClassCode,
        suppliers: queryStringObj.supplierId,
        airline: queryStringObj.airlineCode ? toJson({ Code: queryStringObj.airlineCode, Name: queryStringObj.airlineText }, true)! : undefined,
        onlyDirect: toBoolean(queryStringObj.nonStop),
      });

      let qty = 1;
      if (queryStringObj.airTripType === 'OpenJaw') {
        qty = queryStringObj.destinationCodes.length;
      }

      dispatch(flightSlice.actions.tempSetFlightSearch({ tripType: queryStringObj.airTripType, qty: qty }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fillFromQueryString]);

  const handleSubmit = (values: FlightFormValues) => {
    const originCodes = values.origins.map((item) => item.Code);
    const destinationCodes = values.destinations.map((item) => item.Code);

    if (values.tripType === 'RoundTrip') {
      originCodes.push(destinationCodes[0]);
      destinationCodes.push(originCodes[0]);
    }

    let airline: GetAirlineResponse[0] | undefined = undefined;
    if (values.airline) {
      airline = fromJson(values.airline) as GetAirlineResponse[0];
    }

    const obj: FlightsQueryString = {
      airTripType: values.tripType,

      originCodes: originCodes,
      originsText: values.origins.map((item) => item.Name),
      destinationCodes: destinationCodes,
      destinationsText: values.destinations.map((item) => item.Name),

      departureDateTimes:
        values.tripType === 'RoundTrip'
          ? values.roundTripDates.map((item) => momentToISOUtcDate(item)!)
          : values.departures.map((item) => momentToISOUtcDate(item)!),

      adult: values.adults,
      child: values.children,
      infant: values.infants,

      cabinClassCode: values.cabinClass,
      supplierId: values.suppliers,
      // airlineCode: values.airline?.Code,
      // airlineText: values.airline?.Name,
      airlineCode: airline?.Code,
      airlineText: airline?.Name,
      nonStop: values.onlyDirect,
      isEthnic: props.isEthnic,
    };

    const currentSearchData = convertFlightsQueryStringObjectToSearchData(queryStringObj);
    const newSearchData = convertFlightsQueryStringObjectToSearchData(obj);
    if (!repeatSearchRef.current && isEqual(currentSearchData, newSearchData)) {
      setRepeatSearchModalVisibility(true);
    } else {
      repeatSearchRef.current = false;
      obj.ct = Date.now();
      const queryString = toQueryString(obj, 'bracket');
      history.push(`/flights?${queryString}`);
    }

    addToRecentSearches(obj, 'Flight');
  };

  const handleFormValueChange = (changedValues: FlightFormValues, values: FlightFormValues) => {
    if (values.infants > values.adults) {
      form.setFieldsValue({
        infants: values.adults,
      });
    }
  };

  const handleSearchAgainClicked = () => {
    repeatSearchRef.current = true;
    form.submit();
    setRepeatSearchModalVisibility(false);
  };

  return (
    <>
      <Modal
        visible={repeatSearchModalVisibility}
        okText={t(Translations.Booking.SearchAgain)}
        onOk={handleSearchAgainClicked}
        centered
        onCancel={() => setRepeatSearchModalVisibility(false)}>
        {t(Translations.Booking.RepeatSearchConfirmation)}
      </Modal>
      <Form labelCol={{ span: 24 }} form={form} onFinish={handleSubmit} onValuesChange={handleFormValueChange} className={'flight-search-form'}>
        <FlightTripTypeFormItem />
        <Row gutter={[10, 0]}>
          <Col xs={24}>
            <FlightSearchFlights inline={props.inline} formInstance={form} />
          </Col>
          <Col xs={24} xl={props.inline ? 7 : 24}>
            <FlightSearchPassengers formInstance={form} />
          </Col>
          <Col xs={24} md={props.inline ? 16 : 24} xl={props.inline ? 12 : 24}>
            <FlightSearchOptions
              inline={props.inline}
              isEthnic={props.isEthnic}
              defaultAirline={
                queryStringObj.airlineText && queryStringObj.airlineCode ? { Code: queryStringObj.airlineCode, Name: queryStringObj.airlineText } : undefined
              }
            />
          </Col>
          <Col xs={24} md={props.inline ? 8 : 24} xl={props.inline ? 5 : 24}>
            <div className={props.inline ? 'align-with-formItems-md align-with-formItems-lg align-with-formItems-xl text-md-right' : ''}>
              <FormItemActions
                formInstance={form}
                submitIcon={<SearchOutlined />}
                submitText={t(Translations.Common.Search)}
                submitPending={props.submitPending}
                submitPendingIconOnly
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default FlightSearchForm;
