import React from 'react';

import { DefinedRoute } from '../../types';

const DomesticHotelRoutes = (): readonly DefinedRoute[] => [
  {
    type: 'Route',
    path: '/domestic/hotels',
    children: React.lazy(() => import('./pages/DomesticHotels/DomesticHotels')),
    exact: true,
  },
  {
    type: 'Route',
    path: `/domestic/hotel`,
    children: React.lazy(() => import('./pages/DomseticHotelDetails/DomseticHotelDetails')),
    exact: true,
  },
  {
    type: 'Route',
    path: `/domestic/hotel/prereserve`,
    children: React.lazy(() => import('./pages/DomesticHotelPI/DomesticHotelPI')),
    exact: true,
  },
  {
    type: 'Route',
    path: `/domestic/hotel/capacity`,
    children: React.lazy(() => import('./pages/DomesticHotelCapacity/DomesticHotelCapacity')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/domestic/hotel/payment/confirm',
    children: React.lazy(() => import('./pages/DomesticHotelPaymentConfirm/DomesticHotelPaymentConfirm')),
    exact: true,
  },
];

export default DomesticHotelRoutes;
