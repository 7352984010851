import { MenuOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import ayanAirWhiteLogo from '../../../../assets/ayan-air/ayanAir-white.svg';
import proTravelingLogo from '../../../../assets/proTraveling-logo.svg';
import { Env } from '../../../../utils/env';
import { toAbsoluteUrl } from '../../../../utils/helpers';
import classes from './AuthPageHeader.module.scss';

const AuthPageHeader: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div
      className={`${classes.mainHeader} ${
        Env.TenantHost === 'AyanAir' ? classes.AyanAirHeader : Env.TenantHost === 'ProTraveling' ? classes.ProTravelingHeader : ''
      }`}>
      <div className={'page-container'}>
        <Row justify={Env.TenantHost === 'AyanAir' ? 'start' : 'space-between'}>
          <Col>
            <Link to="/" className={`d-block ${Env.TenantHost !== 'ProTraveling' ? 'my-1' : ''}`}>
              {Env.TenantHost === 'ProTraveling' && <img alt="Logo" className={`${classes.loginLogo} ${classes.ProTravelingLogo}`} src={proTravelingLogo} />}
              {Env.TenantHost === 'AyanAir' && <img alt="Logo" className={classes.loginLogo} src={ayanAirWhiteLogo} />}
              {Env.TenantHost === 'iTours' && (
                <>
                  <img alt="Logo" className={classes.loginLogo} src={toAbsoluteUrl('/media/logos/itours-logo.svg')} />
                  <h3 className={classes.slogan}>Travel Management Platform</h3>
                </>
              )}
            </Link>
          </Col>
          <Col className={`position-static ${classes.headerMenusHolder}`}>
            {Env.TenantHost === 'iTours' && (
              <Row gutter={[15, 15]} align={'middle'}>
                <Col>
                  <Link to={'/auth/login'} className="text-muted">
                    Login
                  </Link>
                </Col>
                <Col>
                  <Link to={'/auth/signUp'} className="text-muted">
                    Register
                  </Link>
                </Col>
              </Row>
            )}
            <div className="menu">
              {Env.TenantHost === 'AyanAir' || (
                <button
                  type="button"
                  className={classes.mobileMenuBtn}
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen);
                  }}>
                  <MenuOutlined />
                </button>
              )}
              <ul className={`${Env.TenantHost === 'AyanAir' ? classes.ayanAirMainMenuList : classes.mainMenuList} ${mobileMenuOpen ? classes.open : ''}`}>
                <li className={classes.mainMenuListItem}>
                  <NavLink to={'/auth/login'} className={classes.mainMenuLink}>
                    Home
                  </NavLink>
                </li>
                {Env.TenantHost === 'iTours' && (
                  <li className={classes.mainMenuListItem}>
                    <div className={classes.openSubmenuOnHover}>
                      <span className={classes.mainMenuLink}>Products</span>
                      <div className={classes.mainMenuSubMenuHolder}>
                        <NavLink to={'/products/B2BSellingPlatform'} className={classes.submenu}>
                          B2B Selling Platform
                        </NavLink>
                        <NavLink to={'/products/B2CBookingSystem'} className={classes.submenu}>
                          B2C Booking System
                        </NavLink>
                        <NavLink to={'/products/whiteLabel'} className={classes.submenu}>
                          White Label
                        </NavLink>
                        <NavLink to={'/products/api'} className={classes.submenu}>
                          API
                        </NavLink>
                      </div>
                    </div>
                  </li>
                )}
                <li className={classes.mainMenuListItem}>
                  <NavLink to={'/about'} className={classes.mainMenuLink}>
                    About us
                  </NavLink>
                </li>
                {Env.TenantHost === 'iTours' && (
                  <li className={classes.mainMenuListItem}>
                    <NavLink to={'/support'} className={classes.mainMenuLink}>
                      Customer Support
                    </NavLink>
                  </li>
                )}
                <li className={classes.mainMenuListItem}>
                  <NavLink to={'/contact'} className={classes.mainMenuLink}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <div
                className={classes.overlay}
                onClick={() => {
                  setMobileMenuOpen(false);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AuthPageHeader;
