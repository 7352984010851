import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Row, Select, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { toJson } from '../../../../../utils/helpers';
import { showSuppliersEnabled } from '../../../../booking';
import { GetAirlineResponse } from '../../../apiTypes';
import { getAirlineHttp } from '../../../http/flight-http';
import { flightSuppliersSelector } from '../../..';

type Props = {
  inline?: boolean;
  isEthnic: boolean;
  defaultAirline?: { Code: string; Name: string };
};

const FlightSearchOptions: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const suppliers = useAppSelector(flightSuppliersSelector);

  const usageSuppliers = useMemo(() => {
    return suppliers.filter((f) => f.supplierId !== 17);
  }, [suppliers]);

  const [airlines, setAirlines] = useState<GetAirlineResponse>();

  const getAirlineApi = useHttpCall(getAirlineHttp);

  const debouncedGetAirline = debounce(async (e) => {
    const response = await getAirlineApi.call<GetAirlineResponse>({ value: e });
    if (response && response.success) {
      setAirlines(response.result!.length > 0 ? response.result : []);
    }
  });

  const handleAirlineInputChanged = async (value: string) => {
    if (value.length > 1) {
      await debouncedGetAirline(value);
    }
  };

  return (
    <Row gutter={[10, 0]}>
      <Col xs={12} md={props.inline ? 6 : 12}>
        <Form.Item className="mb-4" name="cabinClass" label={t(Translations.Flight.CabinClass)} initialValue="Economy">
          <Select>
            <Select.Option value="Economy">{t(Translations.Flight.Economy)}</Select.Option>
            <Select.Option value="Premium">{t(Translations.Flight.Premium)}</Select.Option>
            <Select.Option value="Business">{t(Translations.Flight.Business)}</Select.Option>
            <Select.Option value="Firstclass">{t(Translations.Flight.FirstClass)}</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={12} md={props.inline ? 6 : 12}>
        <Form.Item className="mb-4" name="airline" label={t(Translations.Flight.Airline)}>
          <Select onSearch={handleAirlineInputChanged} showSearch filterOption={false} loading={getAirlineApi.pending} allowClear>
            {airlines
              ? airlines.map((item) => (
                  <Select.Option key={item.Id} value={toJson(item)!}>
                    {item.Name}
                  </Select.Option>
                ))
              : props.defaultAirline && <Select.Option value={toJson(props.defaultAirline, true)!}>{props.defaultAirline.Name}</Select.Option>}
          </Select>
        </Form.Item>
      </Col>
      {!props.isEthnic && (
        <>
          {showSuppliersEnabled() && (
            <Col xs={12} md={props.inline ? 6 : 12}>
              <Form.Item
                className="mb-4"
                name="suppliers"
                label={
                  <>
                    {t(Translations.Booking.Supplier)}
                    <Tooltip title={t(Translations.Flight.FlightSearchFormSupplierHelp)}>
                      <InfoCircleOutlined className={'mx-2'} />
                    </Tooltip>
                  </>
                }>
                <Select placeholder={t(Translations.Common.All)} allowClear>
                  {usageSuppliers.map((item) => (
                    <Select.Option key={item.supplierId} value={item.supplierName}>
                      {item.supplierName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={12} md={props.inline ? 6 : 12}>
            <div className="align-with-formItems">
              <Form.Item className="mb-4" name="onlyDirect" valuePropName="checked">
                <Checkbox value>{t(Translations.Flight.DirectOnly)}</Checkbox>
              </Form.Item>
            </div>
          </Col>
        </>
      )}
    </Row>
  );
});

export default FlightSearchOptions;
