import React from 'react';

import { DefinedRoute } from '../../types';

const prefix = '/flights';
const FlightRoutes = (): readonly DefinedRoute[] => [
  {
    type: 'Route',
    path: prefix,
    exact: true,
    children: React.lazy(() => import('./pages/Flights/Flights')),
  },
  {
    type: 'Route',
    path: `${prefix}/flightPI`,
    exact: true,
    children: React.lazy(() => import('./pages/FlightPI/FlightPI')),
  },
  {
    type: 'Route',
    path: `${prefix}/ticketing`,
    exact: true,
    children: React.lazy(() => import('./pages/FlightTicketing/FlightTicketing')),
  },
  {
    type: 'Route',
    path: `${prefix}/pnrresult`,
    exact: true,
    children: React.lazy(() => import('./pages/FlightPNRResult/FlightPNRResult')),
  },
  {
    type: 'Route',
    path: `${prefix}/payment/confirm`,
    children: React.lazy(() => import('./pages/FlightPaymentConfirm/FlightPaymentConfirm')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/reserves`,
    children: React.lazy(() => import('./pages/FlightsReserves/FlightsReserves')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/reserves/reserve`,
    children: React.lazy(() => import('./pages/FlightReserveDetails/FlightReserveDetails')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/fare/quotes`,
    exact: true,
    children: React.lazy(() => import('./pages/FareQuoteList/FareQuoteList')),
  },
];

export default FlightRoutes;
