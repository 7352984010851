import { ApiDataItem } from '../../../types';

export enum PaymentApiUrls {
  // //AccountNumbers
  // GetAllAccountNumbers = '/api/services/app/AccountNumbers/GetAll',
  //
  // //Deposit
  // DepositTransactions = '/api/v1/Affiliate/Deposit/Transactions',
  // DepositBalances = '/api/v1/Affiliate/Deposit/Balances',
  //
  // //ManualReceiptClient
  // CreateManualReceiptClient = '/api/services/app/ManualReceiptClient/Create',
  // GetAllManualReceiptClients = '/api/services/app/ManualReceiptClient/GetAll',
  //
  // //DepositGateway
  // GetDepositGateways = '/api/v1/Affiliate/Deposit/Gateway/GetGateways',
  // DepositGatewayMakeToken = '/api/v1/Affiliate/Deposit/Gateway/MakeToken',
  //
  // //RefundRequest
  // Refund = '/api/services/app/RefundRequest/Refund',
  //
  // //ReserveDeposit
  // ReserveDepositBalance = '/api/v1/Affiliate/Reserves/Deposit/Balance',
  // ConfirmByDeposit = '/api/v1/Affiliate/Reserves/Deposit/ConfirmByDeposit',
  // DepositRefund = '/api/v1/Affiliate/Reserves/Deposit/DepositRefund',
  //
  // //ReserveGateway
  // GetReserveGateways = '/api/v1/Reserves/Gateway/GetGateways',
  // ReserveGatewayMakeToken = '/api/v1/Reserves/Gateway/MakeToken',
  //
  // //TenantDepositGateway
  // GetTransactionsToExcel = '/api/services/app/TenantDepositGateway/GetTransactionsToExcel',
  //
  //
  //
  //

  //AccountNumbers
  GetAllAccountNumbers = '/api/services/app/AccountNumbers/GetAll',

  //Deposit
  DepositTransactions = 'api/services/app/TenantTransactionDeposit/GetAll',

  DepositBalances = '/api/services/app/TenantDeposit/GetBalances',

  //ManualReceiptClient
  CreateManualReceiptClient = '/api/services/app/ManualReceipt/Create',

  GetAllManualReceiptClients = '/api/services/app/ManualReceipt/GetAll',

  //DepositGateway
  GetDepositGateways = '/api/services/app/TenantDepositGateway/GetGateways',

  DepositGatewayMakeToken = '/api/services/app/TenantDepositGateway/MakeToken',

  //RefundRequest
  Refund = '/api/services/app/TenantRefundRequest/Refund',

  //ReserveDeposit
  ReserveDepositBalance = '/api/services/app/TenantDepositReserve/GetBalance',

  ConfirmByDeposit = '/api/services/app/TenantDepositReserve/ConfirmByDeposit',

  DepositRefund = '/api/services/app/TenantDepositReserve/DepositRefund',

  //ReserveGateway
  GetReserveGateways = '/api/services/app/TenantReserveBankGateway/GetAll',

  ReserveGatewayMakeToken = '/api/services/app/TenantReserveBankGateway/MakeToken',

  //TenantDepositGateway
  GetTransactionsToExcel = '/api/services/app/TenantTransactionDeposit/GetAllToExcel',
}

export const PaymentApiDataMap: Map<string, ApiDataItem> = new Map();
