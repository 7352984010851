import { ApiDataItem } from '../../../types';

export enum FlightApiUrls {
  //BookingFlight
  GetAirport = '/api/services/app/BookingFlight/GetAirport',
  GetAirline = '/api/services/app/BookingFlight/GetAirline',
  LowFareSearch = '/api/services/app/BookingFlight/LowFareSearch',
  GetFlightRules = '/api/services/app/BookingFlight/GetFlightRules',
  Validate = '/api/services/app/BookingFlight/Validate',
  ReservePNR = '/api/services/app/BookingFlight/ReservePNR',
  GetFlightReserveById = '/api/services/app/BookingFlight/GetFlightReserveById',
  GetDirectTicketById = '/api/services/app/BookingFlight/GetDirectTicketById',
  CancelPNR = '/api/services/app/BookingFlight/CancelPNR',
  GetPNRDetails = '/api/services/app/BookingFlight/GetPNRDetails',
  PricePNR = '/api/services/app/BookingFlight/PricePNR',
  IssuePNR = '/api/services/app/BookingFlight/IssuePNR',
  VoidTicket = '/api/services/app/BookingFlight/VoidTicket',
  GetCancellationPolicies = '/api/services/app/BookingFlight/GetCancellationPolicies',
  VoidAndCancel = '/api/services/app/BookingFlight/VoidAndCancel',
  GetReserveList = '/api/services/app/BookingFlight/GetReserveList',
  GetFlightSuppliers = '/api/services/app/BookingFlight/GetFlightSuppliers',
  GetCalendarRoute = '/api/services/app/BookingFlight/GetCalendarRoute',
  GetAlternatives = '/api/services/app/BookingFlight/GetAlternatives',

  GetTime = '/api/services/app/BookingFlight/GetTime',

  //TenantReserve
  GetAllTenantReserves = '/api/services/app/TenantReserve/GetAll',

  //Fare
  FareQuote = '/api/services/app/Fare/FareQuote',
  FarePenalties = '/api/services/app/Fare/GetFarePenalties',
  FareRules = '/api/services/app/Fare/GetFareRules',
  FareRoutes = '/api/services/app/Fare/GetFareRoutes',

  //File
  DownloadFareQuoteExcel = '/DownloadFareQuoteExcel',
}

export const FlightApiDataMap: Map<string, ApiDataItem> = new Map();
