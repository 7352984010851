import React from 'react';

import { Env } from '../../../../utils/env';
import AuthPageAyanAir from '../AuthPageAyanAir/AuthPageAyanAir';
import AuthPageItours from '../AuthPageItours/AuthPageItours';
import AuthPageProTraveling from '../AuthPageProTraveling/AuthPageProTraveling';

const AuthPage: React.VFC = () => {
  if (Env.TenantHost === 'iTours') {
    return <AuthPageItours />;
  }

  if (Env.TenantHost === 'ProTraveling') {
    return <AuthPageProTraveling />;
  }

  if (Env.TenantHost === 'AyanAir') {
    return <AuthPageAyanAir />;
  }

  return null;
};

export default AuthPage;
