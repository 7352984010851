import { ConfigProvider } from 'antd';
import { enableMapSet } from 'immer';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { LayoutSplashScreen, MetronicLayoutProvider, MetronicSplashScreenProvider } from './_metronic/layout';
import { TestAxiosData, setupAxiosInstances } from './features/http';
import { initLocalization } from './features/localization';
import { initializeCulture } from './features/localization/localization-config';
import { cleanStorageOnBeforeVersion } from './features/operations';
import { initializeStore, store } from './features/redux';
import { IdentityAxiosData } from './http';
import { AccountAxiosData } from './modules/account';
import { AdminAxiosData } from './modules/admin';
import { AuthenticationAxiosData } from './modules/authentication';
import { DomesticFlightAxiosData } from './modules/domesticFlight';
import { DomesticHotelAxiosData } from './modules/domesticHotel';
import { FlightAxiosData } from './modules/flight';
import { HotelAxiosData } from './modules/hotel';
import { HotelReferenceAxiosData } from './modules/hotel/http/hotelReference-http';
import { PackageAxiosData } from './modules/package';
import { PaymentAxiosData } from './modules/payment/http/payment-http';
import Routes from './modules/Routes';
import { App, AppCssImporter, CoordinatorAxiosData } from './modules/shared';
import Initialization from './modules/shared/components/Initialization/Initialization';
import LoadingPortal from './modules/shared/components/LoadingPortal/LoadingPortal';
import MainErrorBoundary from './modules/shared/components/MainErrorBoundary/MainErrorBoundary';
import { SharedAxiosData } from './modules/shared/http/shared-http';
import { initTenantHostConfig } from './modules/shared/TenantHostConfig';
import { TicketingAxiosData } from './modules/ticketing';
import { LocalizationHelpers, getAntdLocale } from './utils/helpers';

const criticalError = localStorage.getItem('__CE__');
localStorage.removeItem('__CE__');

initTenantHostConfig();

const localStorageCleaned = cleanStorageOnBeforeVersion(1);
if (localStorageCleaned) {
  initializeCulture();
}

enableMapSet();

initLocalization().then(() => {
  setupAxiosInstances(
    [
      AuthenticationAxiosData,
      TestAxiosData,
      AccountAxiosData,
      AdminAxiosData,
      PaymentAxiosData,
      HotelAxiosData,
      HotelReferenceAxiosData,
      FlightAxiosData,
      CoordinatorAxiosData,
      PackageAxiosData,
      DomesticFlightAxiosData,
      DomesticHotelAxiosData,
    ],
    [IdentityAxiosData, TicketingAxiosData, SharedAxiosData]
  );
});

initializeStore();

const htmlDirection = LocalizationHelpers.getHtmlDirection();

ReactDOM.render(
  criticalError ? (
    <>
      <h1 style={{ color: 'red' }}>Critical Error</h1>
      <h2>Contact Support</h2>
      <h3>{criticalError}</h3>
    </>
  ) : (
    // <React.StrictMode>
    <Provider store={store}>
      <LoadingPortal />
      <MetronicLayoutProvider>
        <MetronicSplashScreenProvider>
          <AppCssImporter direction={htmlDirection}>
            <ConfigProvider direction={htmlDirection} locale={getAntdLocale().default}>
              <BrowserRouter>
                <MainErrorBoundary>
                  <Suspense fallback={<LayoutSplashScreen />}>
                    <Initialization loading={<LayoutSplashScreen />}>
                      <App>
                        <Routes />
                      </App>
                    </Initialization>
                  </Suspense>
                </MainErrorBoundary>
              </BrowserRouter>
            </ConfigProvider>
          </AppCssImporter>
        </MetronicSplashScreenProvider>
      </MetronicLayoutProvider>
    </Provider>
  ),
  // </React.StrictMode>,
  document.getElementById('root')
);
