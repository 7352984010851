import { CreditCardOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';
import { toQueryString } from '../../../../utils/helpers';
import { PaymentMethodQueryString } from '../../../payment/types';

type Props = {
  reserveId: string;
  username: string;
  type: 'Hotel' | 'Flight';
};
const PaymentLinkButton: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`${process.env.REACT_APP_AppAddress}/payment/method?${toQueryString({
        reserveId: props.reserveId,
        username: props.username,
        type: props.type,
      } as PaymentMethodQueryString)}`}>
      <Button type="primary" htmlType="button" icon={<CreditCardOutlined />}>
        {t(Translations.Common.Payment)}
      </Button>
    </a>
  );
});

export default PaymentLinkButton;
