import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import sharedSlice from '../../modules/shared/redux/shared-slice';
import { AbpError, AbpResponse, ApiResponse } from '../../types';
import { handleAxiosError } from './AxiosHelpers';
import { getJsonStorageWithConditions, setJsonStorage } from './LocalStorageHelpers';

export function* abpApi(
  httpPromise: (data: any) => Promise<ApiResponse<any>>,
  apiPayload: any,
  pendingAction: ActionCreatorWithoutPayload | undefined,
  errorAction: ActionCreatorWithPayload<AbpError> | undefined,
  throwOnError = false
) {
  if (pendingAction) {
    yield put(pendingAction());
  }
  try {
    const axiosResponse: AxiosResponse = yield call(httpPromise, apiPayload);
    const error = handleAxiosError(axiosResponse);
    if (error) {
      if (errorAction) {
        yield put(errorAction(error));
      }
      return;
    } else {
      return (axiosResponse as AxiosResponse<AbpResponse>).data;
    }
  } catch (e) {
    if (throwOnError) {
      throw e;
    }
  }
}

export function* initializeFromLocalStorageAndApi<ApiResponseT>(
  localStorageKey: string,
  initializationState: string,
  conditionFn: (obj: ApiResponseT) => boolean,
  httpPromise: (data: any) => Promise<ApiResponse<ApiResponseT>>,
  apiPayload: any,
  onSuccess: (obj: ApiResponseT) => Generator | void,
  expireInSeconds: number,
  throwOnError: boolean,

  //ToDo : Make it as a configuration option
  forceApi?: boolean
) {
  const storageObj = getJsonStorageWithConditions<ApiResponseT>(localStorageKey, conditionFn);
  if (storageObj && !forceApi) {
    yield onSuccess(storageObj);
    return storageObj;
  } else {
    const apiResponse: AxiosResponse<AbpResponse<ApiResponseT>> = yield call(httpPromise, apiPayload);
    const error = handleAxiosError(apiResponse);
    if (error) {
      if (throwOnError) {
        yield put(sharedSlice.actions.setInitializationError({ initializationState, abpError: error }));
      }
      return undefined;
    } else {
      const {
        data: { result },
      } = apiResponse as AxiosResponse<AbpResponse<ApiResponseT>>;
      yield onSuccess(result!);
      setJsonStorage(localStorageKey, result, expireInSeconds);
      return result;
    }
  }
}
