import { ApiDataItem } from '../../types';

export const IdentityApiUrls = {
  Notification: {
    GetUserNotification: '/api/services/app/Notification/GetUserNotifications',
    SetAllNotificationsAsRead: '/api/services/app/Notification/SetAllNotificationsAsRead',
    SetNotificationAsRead: '/api/services/app/Notification/SetNotificationAsRead',
    DeleteNotification: '/api/services/app/Notification/DeleteNotification',
    DeleteAllUserNotifications: '/api/services/app/Notification/DeleteAllUserNotifications',
  },
};

export const IdentityApiDataMap: Map<string, ApiDataItem> = new Map();
