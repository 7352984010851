import { TenantHost } from '../../../types';

export const CurrencyName = {
  USD: 'USD',
  EUR: 'EUR',
  IRR: 'IRR',
  SEK: 'SEK',
  NOK: 'NOK',
  DKK: 'DKK',
  AED: 'AED',
  TRY: 'TRY',
};

export const CurrencySymbol = {
  USD: '$',
  EUR: '€',
  IRR: 'ریال',
  SEK: 'kr',
  NOK: 'kr',
  DKK: 'kr',
  AED: 'د.إ',
  TRY: '₺',
};

export const SupportedCurrencies: {
  Name: string;
  Symbol: string;
  TenantHosts: TenantHost[];
}[] = [
  {
    Name: CurrencyName.USD,
    Symbol: CurrencySymbol.USD,
    TenantHosts: ['iTours', 'AyanAir'],
  },
  {
    Name: CurrencyName.EUR,
    Symbol: CurrencySymbol.EUR,
    TenantHosts: ['iTours'],
  },
  {
    Name: CurrencyName.IRR,
    Symbol: CurrencySymbol.IRR,
    TenantHosts: ['iTours'],
  },
  {
    Name: CurrencyName.SEK,
    Symbol: CurrencySymbol.SEK,
    TenantHosts: ['iTours'],
  },
  {
    Name: CurrencyName.NOK,
    Symbol: CurrencySymbol.NOK,
    TenantHosts: ['iTours'],
  },
  {
    Name: CurrencyName.DKK,
    Symbol: CurrencySymbol.DKK,
    TenantHosts: ['iTours'],
  },
  {
    Name: CurrencyName.AED,
    Symbol: CurrencySymbol.AED,
    TenantHosts: ['iTours'],
  },
  {
    Name: CurrencyName.TRY,
    Symbol: CurrencySymbol.TRY,
    TenantHosts: ['iTours'],
  },
];
